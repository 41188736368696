import React, { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// STORE
import AppContext from '../../utils/context/AppContext';

const RequesterIdOptions = () => {
  const { updateRequesterId, requestersList } = useContext(AppContext);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);

  return (
    requestersList.length > 0 && (
      <>
        {/* <div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>
        <div>{`inputValue: '${inputValue}'`}</div>
        <br /> */}
        <Autocomplete
          style={{
            display: 'inline-block',
            width: '300px',
            marginLeft: '20px',
            marginRight: '20px',
          }}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);

            updateRequesterId(newValue === null ? '' : newValue.id);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id='requesters-id-selector'
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          options={requestersList}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Requester Id'
              size='small'
              variant='outlined'
              required
            />
          )}
        />
      </>
    )
  );
};

export default RequesterIdOptions;
