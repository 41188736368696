import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ZonesFilterOptions = ({ value = '', setValue }) => {
  const [inputValue, setInputValue] = useState('');

  const options = [
    'pending',
    'assigned',
    'arriving_pickup',
    'at_pickup',
    'in_transit',
    'arriving_dropoff',
    'at_dropoff',
    '',
  ];

  return (
    <>
      {/* <div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>
      <div>{`inputValue: '${inputValue}'`}</div>
      <br /> */}
      <Autocomplete
        style={{
          display: 'inline-block',
          width: '280px',
          marginRight: '20px',
        }}
        value={value}
        onChange={(event, newValue) => {
          if (!newValue) {
            setValue('');
          } else {
            setValue(newValue);
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          if (!newInputValue) {
            setInputValue('');
          } else {
            setInputValue(newInputValue);
          }
        }}
        id='zones-filter-selector'
        options={options}
        getOptionSelected={(option, value) => option === value}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Filter by status'
            size='small'
            variant='outlined'
          />
        )}
      />
    </>
  );
};

export default ZonesFilterOptions;
