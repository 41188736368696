import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

// MATERIAL UI COMPONENTS
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';

// ICONS
import AssignmentIcon from '@material-ui/icons/Assignment';
import HistoryIcon from '@material-ui/icons/History';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CancelIcon from '@material-ui/icons/Cancel';

// AUTH0
import { useAuth0 } from '@auth0/auth0-react';

// LOGO
import LogoText from '../assets/sort_logo_text.png';

// STORE
import AppContext from '../utils/context/AppContext';
import axios from 'axios';

const atheneaUrl = process.env.REACT_APP_ATHENEA_URL;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  title: {
    flexGrow: 1,
  },
  imgLogo: {
    width: '70px',
  },
  avatarContainer: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  avatarSize: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  textUserAvatarContainer: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  textUser: {
    fontWeight: 'bold',
  },
}));

const Layout = ({ children }) => {
  const { updateUser, updateRequestersList, updateZonesList } =
    useContext(AppContext);
  const classes = useStyles();
  const theme = useTheme();
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAuthOptions = Boolean(anchorEl);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      getDataUser();
      getRequestersList();
      getZonesList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const getDataUser = async () => {
    const accessToken = await getAccessTokenSilently();
    const { data } = await axios.get('https://kiwibot.us.auth0.com/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    updateUser(data);
  };

  const getRequestersList = async () => {
    const { data } = await axios.get(
      `${atheneaUrl}/v1/requesters?key=${process.env.REACT_APP_ATHENEA_API_KEY}`
    );
    updateRequestersList(data);
  };

  const getZonesList = async () => {
    const { data } = await axios.get(
      `${atheneaUrl}/v1/zones?geojson=false&key=${process.env.REACT_APP_ATHENEA_API_KEY}`
    );
    updateZonesList(data.zones);
  };

  const handleMenuAuthOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAuthOptions = () => {
    setAnchorEl(null);
    logout({ returnTo: window.location.origin });
    updateUser({});
    updateRequestersList({});
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const initialsName = `${
    user.given_name ? user.given_name.charAt(0).toUpperCase() : 'K'
  }${user.family_name ? user.family_name.charAt(0).toUpperCase() : 'B'}`;

  return (
    isAuthenticated && (
      <div className={classes.root}>
        <CssBaseline />
        {/* AppBar */}
        <AppBar
          position='fixed'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: openDrawer,
          })}>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, openDrawer && classes.hide)}>
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title} noWrap>
              Customer Success Platform
            </Typography>
            <img
              src={LogoText}
              className={classes.imgLogo}
              alt='Kiwibot Logo'
            />

            <div>
              <IconButton
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleMenuAuthOptions}
                color='inherit'>
                <AccountCircle />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openAuthOptions}
                onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={handleCloseAuthOptions}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        {/* Drawer */}
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={openDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <div className={classes.drawerContainer}>
            <div className={classes.avatarContainer}>
              <Avatar className={classes.avatarSize}>{initialsName}</Avatar>
            </div>
            <div className={classes.textUserAvatarContainer}>
              <Typography variant='subtitle1' className={classes.textUser}>
                {user.name}
              </Typography>
            </div>
            <Divider />
            <List>
              <ListItem button component={Link} to='/'>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary='Active Orders' />
              </ListItem>
              <ListItem button component={Link} to='/history'>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary='Completed orders' />
              </ListItem>
              <ListItem button component={Link} to='/canceled'>
                <ListItemIcon>
                  <CancelIcon />
                </ListItemIcon>
                <ListItemText primary='Canceled orders' />
              </ListItem>
            </List>
          </div>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: openDrawer,
          })}>
          <div className={classes.drawerHeader} />
          {children}
        </main>
      </div>
    )
  );
};

export default Layout;
