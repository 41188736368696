import React, { useState, useMemo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import RequesterIdOptions from '../components/resources/RequesterIdOptions';
import ZoneIdOptions from '../components/resources/ZonesIdOptions';
import ZonesFilterOptions from '../components/resources/ZonesFilterOptions';

import axios from 'axios';
import { useQuery } from 'react-query';

// STORE
import AppContext from '../utils/context/AppContext';

// DayJS
import dayjs from 'dayjs';
import RowOrder from '../components/resources/RowOrder';
var relativeTime = require('dayjs/plugin/relativeTime');
var updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    // past: '%s ago',
    past: '%s ',
    s: 'A few seconds',
    m: '1 min',
    mm: '%d mins',
    h: '1 hr',
    hh: '%d hrs',
    d: '1 d',
    dd: '%d d',
    M: '1 month',
    MM: '%d months',
    y: '1 y',
    yy: '%d y',
  },
});

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    marginTop: '20px',
  },
  textInputFilter: {
    marginRight: '15px',
    width: '280px',
  },
  tableContainer: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  table: {
    minWidth: 650,
  },
}));

const atheneaUrl = process.env.REACT_APP_ATHENEA_URL;

const ActiveOrders = () => {
  const classes = useStyles();
  const { requesterId, zoneId } = useContext(AppContext);
  const [isByZone, setIsByZone] = useState(false);

  const fetchActiveDeliveries = async () => {
    let res;
    if (isByZone === false) {
      res = await axios.get(
        `${atheneaUrl}/v1/requesters/${
          requesterId ?? 'c04ffbc3-1149-4685-9610-6dcfafcf7d0f'
        }/deliveries?activity=open&key=${process.env.REACT_APP_ATHENEA_API_KEY}`
      );
      return res.data.deliveries;
    } else {
      res = await axios.post(
        `${atheneaUrl}/deliveries/zones?activity=open&key=${process.env.REACT_APP_ATHENEA_API_KEY}`,
        {
          zones: zoneId,
        }
      );
      return res.data.data;
    }
  };

  const { status, data } = useQuery('activeDeliveries', fetchActiveDeliveries, {
    refetchInterval: 1000,
    refetchOnWindowFocus: true,
    staleTime: 1000,
    cacheTime: 0,
    enabled: isByZone === false ? requesterId.length >= 10 : zoneId.length > 0,
    keepPreviousData: false,
  });

  const filterOrdersScheduled = (data) => {
    if (!data) return [];
    return data.filter((order) => {
      const currentTime = dayjs();
      const currentTimeCalc = new Date(currentTime.add(30, 'minutes'));
      const estimatedPickupAt = dayjs(order.estimated_pickup_at);

      return estimatedPickupAt.isBefore(dayjs(currentTimeCalc));
    });
  };

  const [externalIdAndClientNameFilter, setExternalIdAndClientNameFilter] =
    useState('');

  const [statusFilter, setStatusFilter] = useState('');
  const [kiwibotFilter, setKiwibotFilter] = useState('');
  const [phoneNumberFilter, setPhoneNumberFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');

  const activeDeliveries = useMemo(() => {
    if (isByZone === false) {
      if (requesterId.length >= 10) {
        if (!data) return [];
        let firstArr = [];
        let secondArr = [];
        let thirdArr = [];
        let fourthArr = [];
        let fifthArr = [];
        if (externalIdAndClientNameFilter.length > 0) {
          firstArr = data.filter((delivery) =>
            delivery.dropoff.name
              ? delivery.dropoff.name
                  .toLowerCase()
                  .includes(externalIdAndClientNameFilter.toLowerCase())
              : [] || delivery.external_id
              ? delivery.external_id
                  .toLowerCase()
                  .includes(externalIdAndClientNameFilter.toLowerCase())
              : []
          );
        }
        if (statusFilter.length > 0) {
          secondArr = data.filter((delivery) =>
            delivery.status
              ? delivery.status
                  .toLowerCase()
                  .includes(statusFilter.toLowerCase())
              : []
          );
        }
        if (kiwibotFilter.length > 0) {
          thirdArr = data.filter((delivery) =>
            delivery.worker_id
              ? delivery.worker_id
                  .toLowerCase()
                  .includes(kiwibotFilter.toLowerCase())
              : []
          );
        }

        if (phoneNumberFilter.length > 0) {
          fourthArr = data.filter((delivery) =>
            delivery.dropoff.phone_number
              ? delivery.dropoff.phone_number
                  .toLowerCase()
                  .includes(phoneNumberFilter.toLowerCase())
              : []
          );
        }

        if (emailFilter.length > 0) {
          fifthArr = data.filter((delivery) =>
            delivery.dropoff.email
              ? delivery.dropoff.email
                  .toLowerCase()
                  .includes(emailFilter.toLowerCase())
              : []
          );
        }

        let finalArr = [
          ...firstArr,
          ...secondArr,
          ...thirdArr,
          ...fourthArr,
          ...fifthArr,
        ];
        finalArr = [...new Set(finalArr)];
        if (finalArr.length === 0) return data ?? [];
        return finalArr;
      }
    } else {
      if (zoneId.length > 0) {
        if (!data) return [];
        let firstArr = [];
        let secondArr = [];
        let thirdArr = [];
        let fourthArr = [];
        let fifthArr = [];
        if (externalIdAndClientNameFilter.length > 0) {
          firstArr = data.filter((delivery) =>
            delivery.dropoff.name
              ? delivery.dropoff.name
                  .toLowerCase()
                  .includes(externalIdAndClientNameFilter.toLowerCase())
              : [] || delivery.external_id
              ? delivery.external_id
                  .toLowerCase()
                  .includes(externalIdAndClientNameFilter.toLowerCase())
              : []
          );
        }
        if (statusFilter.length > 0) {
          secondArr = data.filter((delivery) =>
            delivery.status
              ? delivery.status
                  .toLowerCase()
                  .includes(statusFilter.toLowerCase())
              : []
          );
        }
        if (kiwibotFilter.length > 0) {
          thirdArr = data.filter((delivery) =>
            delivery.worker_id
              ? delivery.worker_id
                  .toLowerCase()
                  .includes(kiwibotFilter.toLowerCase())
              : []
          );
        }

        if (phoneNumberFilter.length > 0) {
          fourthArr = data.filter((delivery) =>
            delivery.dropoff.phone_number
              ? delivery.dropoff.phone_number
                  .toLowerCase()
                  .includes(phoneNumberFilter.toLowerCase())
              : []
          );
        }

        if (emailFilter.length > 0) {
          fifthArr = data.filter((delivery) =>
            delivery.dropoff.email
              ? delivery.dropoff.email
                  .toLowerCase()
                  .includes(emailFilter.toLowerCase())
              : []
          );
        }

        let finalArr = [
          ...firstArr,
          ...secondArr,
          ...thirdArr,
          ...fourthArr,
          ...fifthArr,
        ];
        finalArr = [...new Set(finalArr)];
        if (finalArr.length === 0) return data ?? [];
        return finalArr;
      }
    }
    return [];
  }, [
    data,
    externalIdAndClientNameFilter,
    statusFilter,
    kiwibotFilter,
    requesterId,
    isByZone,
    zoneId,
    emailFilter,
    phoneNumberFilter,
  ]);

  const handleByZone = () => {
    setIsByZone(!isByZone);
  };

  return (
    <div>
      <Typography
        style={{ display: 'flex', alignItems: 'center' }}
        variant='h4'
        component='h2'>
        Active Orders
        {isByZone === false ? <RequesterIdOptions /> : <ZoneIdOptions />}
        <FormGroup style={{ display: 'inline' }} row>
          <FormControlLabel
            control={
              <Switch
                checked={isByZone}
                onChange={handleByZone}
                name='By Zone'
              />
            }
            label='By Zone'
          />
        </FormGroup>
        <Typography variant='body2'>
          {isByZone === false ? requesterId : ''}
        </Typography>
      </Typography>
      <form className={classes.filterContainer} noValidate>
        <TextField
          className={classes.textInputFilter}
          id='filter-externalid-clientname'
          label='Filter by external id or client name'
          variant='outlined'
          size='small'
          defaultValue={externalIdAndClientNameFilter}
          onChange={(e) => {
            setExternalIdAndClientNameFilter(e.target.value);
          }}
        />
        <ZonesFilterOptions value={statusFilter} setValue={setStatusFilter} />
        <TextField
          className={classes.textInputFilter}
          id='filter-kiwibot'
          label='Filter by kiwibot'
          variant='outlined'
          size='small'
          defaultValue={kiwibotFilter}
          onChange={(e) => {
            setKiwibotFilter(e.target.value);
          }}
        />
        <TextField
          className={classes.textInputFilter}
          id='filter-phonenumber'
          label='Filter by Phone Number'
          variant='outlined'
          size='small'
          defaultValue={phoneNumberFilter}
          onChange={(e) => {
            setPhoneNumberFilter(e.target.value);
          }}
        />
        <TextField
          className={classes.textInputFilter}
          id='filter-email'
          label='Filter by Email'
          variant='outlined'
          size='small'
          defaultValue={emailFilter}
          onChange={(e) => {
            setEmailFilter(e.target.value);
          }}
        />
      </form>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Time elapsed</TableCell>
              <TableCell align='left'>ID</TableCell>
              <TableCell align='left'>Client Name</TableCell>
              <TableCell align='left'>Kiwibot</TableCell>
              <TableCell align='left'>Status</TableCell>
              <TableCell align='left'>Pickup</TableCell>
              <TableCell align='left'>Dropoff</TableCell>
              <TableCell align='left'>Status History</TableCell>
              <TableCell align='left'>More info</TableCell>
              <TableCell align='left'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {status === 'success' && (
              <>
                {filterOrdersScheduled(activeDeliveries).map((delivery) => (
                  <RowOrder key={delivery.id} delivery={delivery} />
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ActiveOrders;
