import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Swal from 'sweetalert2';

import statusService from '../../utils/statusService';

const options = ['Cancel order', 'Open door', 'Close door', 'Open tracker'];

const ButtonActions = ({ delivery }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const openDoor = async () => {
    const infoBot = await statusService.openDoor({
      requesterId: delivery.requester_id,
      deliveryId: delivery.id,
    });

    if (infoBot.response) {
      if (infoBot.response.status >= 300) {
        // await Alert(infoBot.response.data.detail.message, 'Error')
        Swal.fire(infoBot.response.data.detail.message.toString(), '', 'error');
      }
    } else {
      Swal.fire('Door openened! Great!', '', 'success');
    }
  };

  const closeDoor = async () => {
    const infoBot = await statusService.closeDoor({
      requesterId: delivery.requester_id,
      deliveryId: delivery.id,
    });

    if (infoBot.response) {
      if (infoBot.response.status >= 300) {
        Swal.fire(infoBot.response.data.detail.message.toString(), '', 'error');
      }
    } else {
      Swal.fire('Door openened! Great!', '', 'success');
    }
  };

  const cancelOrder = async () => {
    const order = await statusService.cancelOrder({
      requesterId: delivery.requester_id,
      deliveryId: delivery.id,
    });

    if (order.response) {
      if (order.response.status >= 300) {
        Swal.fire('Oh no! Something is not right', '', 'error');
      }
    } else {
      Swal.fire('Order successfully canceled', '', 'success');
    }
  };

  const handleClick = () => {
    switch (options[selectedIndex]) {
      case 'Open door':
        Swal.fire({
          title: 'Do you want to open door?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Yes, please`,
          denyButtonText: `Not now`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            openDoor();
          } else if (result.isDenied) {
            Swal.fire('Ok, no problem ;)', '', 'info');
          }
        });
        break;

      case 'Cancel order':
        Swal.fire({
          title: 'Do you want to cancel order?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Yes, please`,
          denyButtonText: `No, sorry`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            cancelOrder();
          } else if (result.isDenied) {
            Swal.fire('Ok, no problem ;)', '', 'info');
          }
        });
        break;

      case 'Close door':
        Swal.fire({
          title: 'Do you want to close door?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Yes, please`,
          denyButtonText: `Not now`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            closeDoor();
          } else if (result.isDenied) {
            Swal.fire('Ok, no problem ;)', '', 'info');
          }
        });
        break;

      case 'Open tracker':
        window.open(
          delivery.tracker_url ?? 'https://www.kiwibot.com/',
          '_blank'
        );
        break;

      default:
        console.warn('Unknown action');
        break;
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      <Grid container direction='column' alignItems='center'>
        <Grid item xs={12}>
          <ButtonGroup
            variant='contained'
            color='primary'
            ref={anchorRef}
            aria-label='split button'>
            <Button onClick={handleClick}>{options[selectedIndex]}</Button>
            <Button
              color='primary'
              size='small'
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label='select merge strategy'
              aria-haspopup='menu'
              onClick={handleToggle}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            style={{ zIndex: '1000' }}
            disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id='split-button-menu'>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          //disabled={index === 2}
                          selected={index === selectedIndex}
                          onClick={(event) =>
                            handleMenuItemClick(event, index)
                          }>
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </>
  );
};

export default ButtonActions;
