import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import DialogWithoutActions from './DialogWithoutActions';
import ButtonActions from './ButtonActions';

// DayJS
import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');
var updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    // past: '%s ago',
    past: '%s ',
    s: 'A few seconds',
    m: '1 min',
    mm: '%d mins',
    h: '1 hr',
    hh: '%d hrs',
    d: '1 d',
    dd: '%d d',
    M: '1 month',
    MM: '%d months',
    y: '1 y',
    yy: '%d y',
  },
});

const RowOrder = ({ delivery }) => {
  const rowColor = (delivery) => {
    switch (delivery.status) {
      case 'pending':
        if (delivery.status_history.pending) {
          let isMins = dayjs(new Date(delivery.status_history.pending))
            .fromNow()
            .includes('min');
          if (isMins) {
            let stringMins = dayjs(new Date(delivery.status_history.pending))
              .fromNow()
              .split(' ');
            if (parseInt(stringMins[0]) >= 10) {
              return 'rgba(255, 0, 0, 0.3)';
            } else {
              return 'transparent';
            }
          }
        }
        break;

      case 'at_pickup':
        if (delivery.status_history.at_pickup) {
          let isMins = dayjs(new Date(delivery.status_history.at_pickup))
            .fromNow()
            .includes('min');
          if (isMins) {
            let stringMins = dayjs(new Date(delivery.status_history.at_pickup))
              .fromNow()
              .split(' ');
            if (parseInt(stringMins[0]) >= 10) {
              return 'rgba(255, 0, 0, 0.3)';
            } else {
              return 'transparent';
            }
          }
        }
        break;

      case 'in_transit':
        if (delivery.status_history.in_transit) {
          let isMins = dayjs(new Date(delivery.status_history.in_transit))
            .fromNow()
            .includes('min');
          if (isMins) {
            let stringMins = dayjs(new Date(delivery.status_history.in_transit))
              .fromNow()
              .split(' ');
            if (parseInt(stringMins[0]) >= 40) {
              return 'rgba(255, 0, 0, 0.3)';
            } else {
              return 'transparent';
            }
          } else if (
            dayjs(new Date(delivery.status_history.in_transit))
              .fromNow()
              .includes('hr')
          ) {
            return 'rgba(255, 0, 0, 0.3)';
          }
        }
        break;

      case 'at_dropoff':
        if (delivery.status_history.at_dropoff) {
          let isMins = dayjs(new Date(delivery.status_history.at_dropoff))
            .fromNow()
            .includes('min');
          if (isMins) {
            let stringMins = dayjs(new Date(delivery.status_history.at_dropoff))
              .fromNow()
              .split(' ');
            if (parseInt(stringMins[0]) >= 10) {
              return 'rgba(255, 0, 0, 0.3)';
            } else {
              return 'transparent';
            }
          }
        }
        break;

      default:
        return 'transparent';
    }
  };

  return (
    <TableRow
      style={{
        backgroundColor: rowColor(delivery),
      }}>
      <TableCell align='left'>
        {dayjs(new Date(delivery.created_at)).fromNow().toString()}
      </TableCell>
      <TableCell align='left'>{delivery.external_id}</TableCell>
      <TableCell align='left'>{delivery.dropoff.name}</TableCell>
      <TableCell align='left'>
        <Typography
          component={'span'}
          variant={'body2'}
          style={{ fontWeight: 'bold' }}>
          {delivery.worker_id ? delivery.worker_id : 'No available yet'}
        </Typography>
      </TableCell>
      <TableCell align='left'>{delivery.status}</TableCell>
      <TableCell align='left'>
        {delivery.pickup.location_name && (
          <Typography
            component={'span'}
            variant={'body2'}
            style={{ fontWeight: 'bold' }}>
            {delivery.pickup.location_name}
          </Typography>
        )}
        <DialogWithoutActions
          buttonText='View pickup info'
          dialogTitle={delivery.pickup.location_name ?? 'Pickup Info'}>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Street:{' '}
            </Typography>{' '}
            {delivery.pickup.street}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              City:{' '}
            </Typography>{' '}
            {delivery.pickup.city}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              State:{' '}
            </Typography>{' '}
            {delivery.pickup.state}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Country:{' '}
            </Typography>{' '}
            {delivery.pickup.country}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Postal Code:{' '}
            </Typography>{' '}
            {delivery.pickup.postal_code}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Instructions:{' '}
            </Typography>{' '}
            {delivery.pickup.instructions
              ? delivery.pickup.instructions
              : 'N/A'}
          </Typography>

          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Phone Number:{' '}
            </Typography>{' '}
            {delivery.pickup.phone_number
              ? delivery.pickup.phone_number
              : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Name:{' '}
            </Typography>{' '}
            {delivery.pickup.name ? delivery.pickup.name : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Email:{' '}
            </Typography>{' '}
            {delivery.pickup.email ? delivery.pickup.email : 'N/A'}
          </Typography>
        </DialogWithoutActions>
      </TableCell>
      <TableCell align='left'>
        {delivery.dropoff.location_name && (
          <Typography
            component={'span'}
            variant={'body2'}
            style={{ fontWeight: 'bold' }}>
            {delivery.dropoff.location_name}
          </Typography>
        )}
        <DialogWithoutActions
          buttonText='View Dropoff info'
          dialogTitle={delivery.dropoff.location_name ?? 'Dropoff Info'}>
          {' '}
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Street:{' '}
            </Typography>{' '}
            {delivery.dropoff.street}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              City:{' '}
            </Typography>{' '}
            {delivery.dropoff.city}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              State:{' '}
            </Typography>{' '}
            {delivery.dropoff.state}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Country:{' '}
            </Typography>{' '}
            {delivery.dropoff.country}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Postal Code:{' '}
            </Typography>{' '}
            {delivery.dropoff.postal_code}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Instructions:{' '}
            </Typography>{' '}
            {delivery.dropoff.instructions
              ? delivery.dropoff.instructions
              : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Phone Number:{' '}
            </Typography>{' '}
            {delivery.dropoff.phone_number
              ? delivery.dropoff.phone_number
              : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Name:{' '}
            </Typography>{' '}
            {delivery.dropoff.name ? delivery.dropoff.name : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Email:{' '}
            </Typography>{' '}
            {delivery.dropoff.email ? delivery.dropoff.email : 'N/A'}
          </Typography>{' '}
        </DialogWithoutActions>
      </TableCell>
      <TableCell align='left'>
        <DialogWithoutActions
          buttonText='View Status History'
          dialogTitle='Status History'>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              1. Pending:{' '}
            </Typography>{' '}
            {delivery.status_history.pending
              ? new Date(delivery.status_history.pending).toLocaleString()
              : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              2. Assigned:{' '}
            </Typography>{' '}
            {delivery.status_history.assigned
              ? new Date(delivery.status_history.assigned).toLocaleString()
              : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              3. Arriving Pickup:{' '}
            </Typography>{' '}
            {delivery.status_history.arriving_pickup
              ? new Date(
                  delivery.status_history.arriving_pickup
                ).toLocaleString()
              : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              4. At Pickup:{' '}
            </Typography>{' '}
            {delivery.status_history.at_pickup
              ? new Date(delivery.status_history.at_pickup).toLocaleString()
              : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              5. In Transit:{' '}
            </Typography>{' '}
            {delivery.status_history.in_transit
              ? new Date(delivery.status_history.in_transit).toLocaleString()
              : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              6. Arriving Dropoff:{' '}
            </Typography>{' '}
            {delivery.status_history.arriving_dropoff
              ? new Date(
                  delivery.status_history.arriving_dropoff
                ).toLocaleString()
              : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              7. At Dropoff:{' '}
            </Typography>{' '}
            {delivery.status_history.at_dropoff
              ? new Date(delivery.status_history.at_dropoff).toLocaleString()
              : 'N/A'}
          </Typography>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              8. Delivered:{' '}
            </Typography>{' '}
            {delivery.status_history.delivered
              ? new Date(delivery.status_history.delivered).toLocaleString()
              : 'N/A'}
          </Typography>
        </DialogWithoutActions>
      </TableCell>
      <TableCell align='left'>
        <DialogWithoutActions
          buttonText='View More info'
          dialogTitle='More info'>
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Quote ID:{' '}
            </Typography>{' '}
            {delivery.quote_id ? delivery.quote_id : 'N/A'}
          </Typography>{' '}
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Requester ID:{' '}
            </Typography>{' '}
            {delivery.requester_id ? delivery.requester_id : 'N/A'}
          </Typography>{' '}
          <Typography
            style={{ display: 'block', marginBottom: '20px' }}
            component={'span'}
            variant={'body2'}>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{ fontWeight: 'bold' }}>
              Tip:{' '}
            </Typography>{' '}
            {delivery.tip ? delivery.tip : 'N/A'}
          </Typography>{' '}
          {delivery.manifest.items.length > 0 ? (
            <>
              <Typography
                style={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '20px',
                }}
                component={'span'}
                variant={'body2'}>
                Items:
                <Typography
                  style={{
                    display: 'block',
                  }}
                  component={'span'}
                  variant={'body2'}>
                  {delivery.manifest.items.map((item) => (
                    <li key={item.name + '-3742893'}>
                      {item.name} x{item.quantity}
                    </li>
                  ))}
                </Typography>
              </Typography>
            </>
          ) : (
            <Typography
              style={{
                display: 'block',
                fontWeight: 'bold',
                marginBottom: '20px',
              }}
              component={'span'}
              variant={'body2'}>
              No items
            </Typography>
          )}
        </DialogWithoutActions>
      </TableCell>
      <TableCell align='left'>
        <ButtonActions delivery={delivery} />
      </TableCell>
    </TableRow>
  );
};

export default RowOrder;
