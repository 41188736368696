import axios from 'axios';

const atheneaUrl = process.env.REACT_APP_ATHENEA_URL;

const openDoor = async ({ requesterId, deliveryId }) => {
  try {
    const { data } = await axios.post(
      `${atheneaUrl}/v1/requesters/${requesterId}/deliveries/${deliveryId}/door?key=${process.env.REACT_APP_ATHENEA_API_KEY}&opened=false`
    );
    return data;
  } catch (error) {
    return error;
  }
};

const closeDoor = async ({ requesterId, deliveryId }) => {
  try {
    const { data } = await axios.post(
      `${atheneaUrl}/v1/requesters/${requesterId}/deliveries/${deliveryId}/door?key=${process.env.REACT_APP_ATHENEA_API_KEY}&opened=true`
    );
    return data;
  } catch (error) {
    return error;
  }
};

const cancelOrder = async ({ requesterId, deliveryId }) => {
  try {
    const { data } = await axios.post(
      `${atheneaUrl}/v1/requesters/${requesterId}/deliveries/${deliveryId}/cancel?key=${process.env.REACT_APP_ATHENEA_API_KEY}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  openDoor,
  closeDoor,
  cancelOrder,
};
