import { Switch, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from 'react-query';

//HOOKS
import AppContext from './utils/context/AppContext';
import useInitialState from './hooks/useInitialState';

// VIEWS and Components
import Layout from './components/Layout';
import ActiveOrders from './pages/ActiveOrders';
import HistoryOrders from './pages/HistoryOrders';
import Loading from './components/resources/Loading';
import CanceledOrders from './pages/CanceledOrders';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#8785fe',
      main: '#5f5cfe',
      dark: '#3733fe',
    },
    secondary: {
      light: ' #6f2288',
      main: '#3D134B',
      dark: '#230b2a',
    },
  },
});

const queryClientActiveOrders = new QueryClient();
const queryClientCompletedOrders = new QueryClient();
const queryClientCanceledOrders = new QueryClient();

function App() {
  const initialState = useInitialState();

  return (
    <AppContext.Provider value={initialState}>
      <ThemeProvider theme={theme}>
        <Layout>
          <Switch>
            <Route exact path='/'>
              <QueryClientProvider client={queryClientActiveOrders}>
                <ActiveOrders />
              </QueryClientProvider>
            </Route>
            <Route path='/history'>
              <QueryClientProvider client={queryClientCompletedOrders}>
                <HistoryOrders />
              </QueryClientProvider>
            </Route>
            <Route path='/canceled'>
              <QueryClientProvider client={queryClientCanceledOrders}>
                <CanceledOrders />
              </QueryClientProvider>
            </Route>
          </Switch>
        </Layout>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loading />,
});
