import React, { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// STORE
import AppContext from '../../utils/context/AppContext';

const ZoneIdOptions = () => {
  const { updateZoneId, zonesList } = useContext(AppContext);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);

  return (
    zonesList.length > 0 && (
      <>
        {/* <div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>
        <div>{`inputValue: '${inputValue}'`}</div>
        <br /> */}
        <Autocomplete
          style={{
            display: 'inline-block',
            width: '500px',
            marginLeft: '20px',
            marginRight: '20px',
          }}
          multiple
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            const zoneIds = newValue.map((item) => item.zone_id);
            updateZoneId(newValue === null ? [] : zoneIds);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id='zones-id-selector'
          getOptionSelected={(option, value) =>
            option.zone_id === value.zone_id
          }
          getOptionLabel={(option) => option.name}
          options={zonesList}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Zone Id'
              size='small'
              variant='outlined'
              fullWidth
              required
            />
          )}
        />
      </>
    )
  );
};

export default ZoneIdOptions;
