const user = {};
const requestersList = {};
const zonesList = {};

const exports = {
  user,
  requestersList,
  zonesList,
  requesterId: '',
  zoneId: [],
};

export default exports;
