import React, { useState, useMemo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DialogWithoutActions from '../components/resources/DialogWithoutActions';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import axios from 'axios';
import { useQuery } from 'react-query';

// STORE
import AppContext from '../utils/context/AppContext';
import RequesterIdOptions from '../components/resources/RequesterIdOptions';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  textInputFilter: {
    marginRight: '15px',
    width: '280px',
  },
  tableContainer: {
    marginTop: '15px',
  },
  table: {
    minWidth: 650,
  },
}));

const atheneaUrl = process.env.REACT_APP_ATHENEA_URL;

const HistoryOrders = () => {
  const classes = useStyles();
  const { requesterId } = useContext(AppContext);

  const [selectedDate, setSelectedDate] = React.useState(
    new Date(Date.now()) || new Date('2019-08-18T21:11:54')
  );

  const formatDate = `${selectedDate.getFullYear()}-${
    selectedDate.getMonth().toString().length === 1
      ? '0' + selectedDate.getMonth()
      : selectedDate.getMonth()
  }-${
    selectedDate.getDate().toString().length === 1
      ? '0' + selectedDate.getDate()
      : selectedDate.getDate()
  }`;

  const fetchDeliveries = async () => {
    const res = await axios.get(
      `${atheneaUrl}/v1/requesters/${
        requesterId ?? 'c04ffbc3-1149-4685-9610-6dcfafcf7d0f'
      }/deliveries?activity=delivered&skip=${formatDate}`
    );
    return res.data;
  };

  const { status, data } = useQuery('historyDeliveries', fetchDeliveries, {
    refetchInterval: 1000,
    refetchOnWindowFocus: true,
    staleTime: 1000,
    cacheTime: 0,
    enabled: requesterId.length >= 10,
    keepPreviousData: false,
  });

  const [externalIdAndClientNameFilter, setExternalIdAndClientNameFilter] =
    useState('');

  const [kiwibotFilter, setKiwibotFilter] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
  };

  const completedOrders = useMemo(() => {
    if (requesterId.length >= 10) {
      if (!data) return [];
      if (externalIdAndClientNameFilter.length > 0) {
        return data.deliveries.filter((delivery) =>
          delivery.dropoff.name
            ? delivery.dropoff.name
                .toLowerCase()
                .includes(externalIdAndClientNameFilter.toLowerCase())
            : null || delivery.external_id
            ? delivery.external_id
                .toLowerCase()
                .includes(externalIdAndClientNameFilter.toLowerCase())
            : null
        );
      }
      if (kiwibotFilter.length > 0) {
        return data.deliveries.filter((delivery) =>
          delivery.worker_id
            ? delivery.worker_id
                .toLowerCase()
                .includes(kiwibotFilter.toLowerCase())
            : null
        );
      }
      return data.deliveries ?? [];
    }
    return [];
  }, [data, externalIdAndClientNameFilter, kiwibotFilter, requesterId]);

  return (
    <div>
      <Typography
        style={{ display: 'flex', alignItems: 'center' }}
        variant='h4'
        component='h2'>
        Completed orders
        <RequesterIdOptions />
        {requesterId && <Typography variant='body2'>{requesterId}</Typography>}
      </Typography>
      <form
        className={classes.filterContainer}
        noValidate
        onSubmit={handleSubmitFilter}>
        <TextField
          className={classes.textInputFilter}
          id='filter-externalid-clientname'
          label='Filter by external id or client name'
          variant='outlined'
          size='small'
          defaultValue={externalIdAndClientNameFilter}
          onChange={(e) => {
            setExternalIdAndClientNameFilter(e.target.value);
          }}
        />
        <TextField
          className={classes.textInputFilter}
          id='filter-kiwibot'
          label='Filter by kiwibot'
          variant='outlined'
          size='small'
          defaultValue={kiwibotFilter}
          onChange={(e) => {
            setKiwibotFilter(e.target.value);
          }}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.textInputFilter}
            autoOk
            variant='inline'
            inputVariant='outlined'
            label='Filter by date'
            format='MM/dd/yyyy'
            value={selectedDate}
            InputAdornmentProps={{ position: 'start' }}
            onChange={(date) => handleDateChange(date)}
            size='small'
          />
        </MuiPickersUtilsProvider>
      </form>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Created At</TableCell>
              <TableCell align='left'>ID</TableCell>
              <TableCell align='left'>Client Name</TableCell>
              <TableCell align='left'>Kiwibot</TableCell>
              <TableCell align='left'>Status</TableCell>
              <TableCell align='left'>Pickup</TableCell>
              <TableCell align='left'>Dropoff</TableCell>
              <TableCell align='left'>Status History</TableCell>
              <TableCell align='left'>More info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {status === 'success' && (
              <>
                {completedOrders.map((delivery) => (
                  <TableRow key={delivery.id}>
                    <TableCell align='left'>
                      {new Date(delivery.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell align='left'>{delivery.external_id}</TableCell>
                    <TableCell align='left'>{delivery.dropoff.name}</TableCell>
                    <TableCell align='left'>
                      <Typography
                        component={'span'}
                        variant={'body2'}
                        style={{ fontWeight: 'bold' }}>
                        {delivery.worker_id
                          ? delivery.worker_id
                          : 'No available yet'}
                      </Typography>
                    </TableCell>
                    <TableCell align='left'>{delivery.status}</TableCell>
                    <TableCell align='left'>
                      <DialogWithoutActions
                        buttonText='View pickup info'
                        dialogTitle={
                          delivery.pickup.location_name ?? 'Pickup Info'
                        }>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Street:{' '}
                          </Typography>{' '}
                          {delivery.pickup.street}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            City:{' '}
                          </Typography>{' '}
                          {delivery.pickup.city}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            State:{' '}
                          </Typography>{' '}
                          {delivery.pickup.state}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Country:{' '}
                          </Typography>{' '}
                          {delivery.pickup.country}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Postal Code:{' '}
                          </Typography>{' '}
                          {delivery.pickup.postal_code}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Instructions:{' '}
                          </Typography>{' '}
                          {delivery.pickup.instructions
                            ? delivery.pickup.instructions
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Phone Number:{' '}
                          </Typography>{' '}
                          {delivery.pickup.phone_number
                            ? delivery.pickup.phone_number
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Name:{' '}
                          </Typography>{' '}
                          {delivery.pickup.name ? delivery.pickup.name : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Email:{' '}
                          </Typography>{' '}
                          {delivery.pickup.email
                            ? delivery.pickup.email
                            : 'N/A'}
                        </Typography>
                      </DialogWithoutActions>
                    </TableCell>
                    <TableCell align='left'>
                      <DialogWithoutActions
                        buttonText='View Dropoff info'
                        dialogTitle={
                          delivery.dropoff.location_name ?? 'Dropoff Info'
                        }>
                        {' '}
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Street:{' '}
                          </Typography>{' '}
                          {delivery.dropoff.street}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            City:{' '}
                          </Typography>{' '}
                          {delivery.dropoff.city}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            State:{' '}
                          </Typography>{' '}
                          {delivery.dropoff.state}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Country:{' '}
                          </Typography>{' '}
                          {delivery.dropoff.country}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Postal Code:{' '}
                          </Typography>{' '}
                          {delivery.dropoff.postal_code}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Instructions:{' '}
                          </Typography>{' '}
                          {delivery.dropoff.instructions
                            ? delivery.dropoff.instructions
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Phone Number:{' '}
                          </Typography>{' '}
                          {delivery.dropoff.phone_number
                            ? delivery.dropoff.phone_number
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Name:{' '}
                          </Typography>{' '}
                          {delivery.dropoff.name
                            ? delivery.dropoff.name
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Email:{' '}
                          </Typography>{' '}
                          {delivery.dropoff.email
                            ? delivery.dropoff.email
                            : 'N/A'}
                        </Typography>{' '}
                      </DialogWithoutActions>
                    </TableCell>
                    <TableCell align='left'>
                      <DialogWithoutActions
                        buttonText='View Status History'
                        dialogTitle='Status History'>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            1. Pending:{' '}
                          </Typography>{' '}
                          {delivery.status_history.pending
                            ? new Date(
                                delivery.status_history.pending
                              ).toLocaleString()
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            2. Assigned:{' '}
                          </Typography>{' '}
                          {delivery.status_history.assigned
                            ? new Date(
                                delivery.status_history.assigned
                              ).toLocaleString()
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            3. Arriving Pickup:{' '}
                          </Typography>{' '}
                          {delivery.status_history.arriving_pickup
                            ? new Date(
                                delivery.status_history.arriving_pickup
                              ).toLocaleString()
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            4. At Pickup:{' '}
                          </Typography>{' '}
                          {delivery.status_history.at_pickup
                            ? new Date(
                                delivery.status_history.at_pickup
                              ).toLocaleString()
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            5. In Transit:{' '}
                          </Typography>{' '}
                          {delivery.status_history.in_transit
                            ? new Date(
                                delivery.status_history.in_transit
                              ).toLocaleString()
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            6. Arriving Dropoff:{' '}
                          </Typography>{' '}
                          {delivery.status_history.arriving_dropoff
                            ? new Date(
                                delivery.status_history.arriving_dropoff
                              ).toLocaleString()
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            7. At Dropoff:{' '}
                          </Typography>{' '}
                          {delivery.status_history.at_dropoff
                            ? new Date(
                                delivery.status_history.at_dropoff
                              ).toLocaleString()
                            : 'N/A'}
                        </Typography>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            8. Delivered:{' '}
                          </Typography>{' '}
                          {delivery.status_history.delivered
                            ? new Date(
                                delivery.status_history.delivered
                              ).toLocaleString()
                            : 'N/A'}
                        </Typography>
                      </DialogWithoutActions>
                    </TableCell>
                    <TableCell align='left'>
                      <DialogWithoutActions
                        buttonText='View More info'
                        dialogTitle='More info'>
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Quote ID:{' '}
                          </Typography>{' '}
                          {delivery.quote_id ? delivery.quote_id : 'N/A'}
                        </Typography>{' '}
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Requester ID:{' '}
                          </Typography>{' '}
                          {delivery.requester_id
                            ? delivery.requester_id
                            : 'N/A'}
                        </Typography>{' '}
                        <Typography
                          style={{ display: 'block', marginBottom: '20px' }}
                          component={'span'}
                          variant={'body2'}>
                          <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontWeight: 'bold' }}>
                            Tip:{' '}
                          </Typography>{' '}
                          {delivery.tip ? delivery.tip : 'N/A'}
                        </Typography>{' '}
                      </DialogWithoutActions>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HistoryOrders;
